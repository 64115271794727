import React from 'react'
import { FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa'

const Footer = () => {
  return (
    <footer className="bg-gray-900">
      <nav className="max-w-6xl mx-auto p-4 md:p-8 text-sm text-center">
        <div className="flex flex-row justify-center">
          <a
            className="p-4 text-white hover:text-teal-400"
            href="//twitter.com/emmishone"
            target="_blank"
            rel="nofollow"
          >
            <FaTwitter size="2em" />
          </a>
          <a
            className="p-4 text-white hover:text-teal-400"
            href="//instagram.com/emshone"
            target="_blank"
            rel="nofollow"
          >
            <FaInstagram size="2em" />
          </a>
          <a
            className="p-4 text-white hover:text-teal-400"
            href="//linkedin.com/in/emily-shone-a3264b56"
            target="_blank"
            rel="nofollow"
          >
            <FaLinkedin size="2em" />
          </a>
        </div>
      </nav>
    </footer>
  )
}

export default Footer
