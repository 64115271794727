import React from 'react'
import { Link } from 'gatsby'
import { FaChevronRight } from 'react-icons/fa'


const GetInTouch = () => {
  return (
    <section id="getInTouch" className="bg-gray-100 text-center pb-4">
      <div className=" max-w-6xl m-auto p-4 md:p-8">
        <h3 className="text-3xl font-bold mb-3">Get in touch</h3>
        <p className="mb-3 text-xl xl:max-w-md xl:m-auto xl:mb-3">
          If you would like to work together, talk about design or ask for
          advice, please get in touch.
        </p>
        <Link className="underline font-semibold hover:font-bold" to="/contact">
          <button className="hover:bg-teal-600 bg-gray-900 text-white font-bold py-2 px-4 rounded-full mt-3 justify-center">
            Contact me <FaChevronRight className="inline" />
          </button>
        </Link>
      </div>
    </section>
  )
}

export default GetInTouch
