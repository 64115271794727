import { graphql, useStaticQuery, Link } from 'gatsby'
import React, { useState } from 'react'

function Header() {
  const [isExpanded, toggleExpansion] = useState(false)
  const site = useStaticQuery(graphql`
    query {
      contentfulSummaryInfo {
        siteLogo {
          title
          file {
            url
          }
        }
      }
    }
  `)



  return (
    <header className="bg-gray-900">
      <div className="flex flex-wrap items-center justify-between max-w-6xl mx-auto p-4 md:p-8">
        <Link className="flex items-center no-underline text-white" to="/">
          {/* <span className="font-bold text-xl tracking-tight hover:underline">
            {site.siteMetadata.title}
          </span> */}
          <img src={site.contentfulSummaryInfo.siteLogo.file.url} alt={site.contentfulSummaryInfo.siteLogo.title} className="h-12 md:h-16" />
        </Link>

        <button
          className="block md:hidden border border-white flex items-center px-3 py-2 rounded text-white"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>

        <nav
          className={`${
            isExpanded ? `block` : `hidden`
            } md:block md:flex md:items-center w-full md:w-auto`}
        >
          {[
            {
              route: `/about`,
              title: `About`,
            },
            {
              route: `/projects`,
              title: `Projects`,
            },
            {
              route: `/talks`,
              title: `Talks`,
            },
            {
              route: `/thoughts`,
              title: `Thoughts`,
            },
            {
              route: `/contact`,
              title: `Contact`,
            },
          ].map(link => (
            <Link
              className="block md:inline-block mt-4 md:mt-0 md:ml-6 no-underline text-gray-400 hover:underline hover:text-white"
              activeStyle={{
                // borderBottom: '3px solid #fff',
                fontWeight: 600,
                textDecoration: 'none',
                color: '#fff',
              }}
              key={link.title}
              to={link.route}
            >
              {link.title}
            </Link>
          ))}
        </nav>
      </div>
    </header>
  )
}

export default Header
