import React from 'react'
import { Link } from 'gatsby'

//TODO Look at the way this works it is not exaclt the right way of doing things

const BreadCrumb = ({ crumbs }) => {
  const breadCrumbItems = crumbs.map(crumb => {
    return (
      <li key={crumb.depth}>
        <Link className={`${crumb.styles} underline hover:font-bold`} to={crumb.url}>
          {crumb.title}
        </Link>
        <span className="mx-2">/</span>
      </li>
    )
  })

  return (
    <section
      id="breadcrumb"
      className="bg-grey-light rounded font-sans w-full mb-3"
    >
      <ol className="list-reset flex text-grey-dark">{breadCrumbItems}</ol>
    </section>
  )
}

export default BreadCrumb
