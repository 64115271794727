import PropTypes from 'prop-types'
import React from 'react'

import Header from './header'
import GetInTouch from './getInTouch/index'
import Footer from './footer'

function Layout({ children }) {

  return (
    <div className="flex flex-col font-sans min-h-screen text-gray-900">
      <Header />

      <main className="flex flex-col flex-1 md:justify-center max-w-6xl mx-auto px-4 py-8 md:p-8 w-full">
        <div className="md:w-5/6 md:mx-auto">
        {children}
        </div>
      </main>
      <GetInTouch />
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
